import $ from 'jquery';
window.$ = $;

$(document).ready(function () {
    $('.c-geomap_region,.c-geomap_continent').click(function () {
        window.location.href =
            $(location).attr('origin') + '/' + $(this).data('js-href');
    });

    $('.c-geomap_continent').hover(
        function () {
            $('[data-js-continent]').html($(this).data('js-title'));
        },
        function () {
            $('[data-js-continent]').html(
                $('[data-js-continent]').data('js-continent-orig'),
            );
        },
    );

    $('.c-geomap_region').hover(
        function () {
            $('[data-js-region]').html($(this).data('js-title'));
        },
        function () {
            $('[data-js-region]').html(
                $('[data-js-region]').data('js-region-orig'),
            );
        },
    );
});
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/**
 * STICKY KATALÓGUS
 */
function toggleSticky() {
    var contentnavi = document.getElementById('js-contentnavi');
    var cntTop = document.getElementById('js-cntTop');
    if (window.pageYOffset >= cntTop.offsetTop) {
        contentnavi.classList.add('js-sticky');
    } else {
        contentnavi.classList.remove('js-sticky');
    }
}

var stickyHandler = debounce(toggleSticky, 12);
window.addEventListener('scroll', stickyHandler);
var windowWidth = window.innerWidth || $(window).width();
if (windowWidth > 991) {
    toggleSticky();
}

/**
 * SEARCHBOX
 */
$('.m-search_btn').click(function () {
    startSearch($('.m-search_form').get(0));
    return false;
});

$('.m-search_input[type="text"]').keypress(function (e) {
    if (e.keyCode === 13) {
        startSearch($('.m-search_form').get(0));
    }
});

function startSearch(form) {
    var searchProviderSelector = $('.m-search_radioBtn:checked');
    var url;
    switch (searchProviderSelector.val()) {
        case 'google':
            //url = "https://www.startlap.hu/web/";
            url = $(form).data('js-search-url');
            $('input[name="sise"]').remove();
            $(form).append('<input type="hidden" name="sise" value="a"/>');
            break;
        case 'startlap':
            url = $(form).data('js-search-url');
            $('input[name="sise"]').remove();
            $(form).append('<input type="hidden" name="sise" value="l"/>');
            break;
    }
    form.action = url;
    form.submit();
}
